import { useNavigate } from '@solidjs/router'
import { Component, createEffect, createResource, createSignal, For } from 'solid-js'
import Stripe from 'stripe'
import Button from '~/components/button/button'
import EntrepriseSubscriptionEntry from '~/components/entreprise-subscription-entry/entreprise-subscription-entry'
import SubscriptionsEntrepriseUpdateTable from '~/components/subscriptions-entreprise-update-table/subscriptions-entreprise-update-table'
import { Cart, SubscriptionsEntrepriseProps } from '~/components/subscriptions-entreprise/subscriptions-entreprise.interfaces'
import styles from '~/components/subscriptions-entreprise/subscriptions-entreprise.module.scss'
import TogglePill from '~/components/toggle-pill/toggle-pill'
import { PillOption } from '~/components/toggle-pill/toggle-pill.interfaces'
import { setLoadingState } from '~/services/loading/loading'
import { getCheckoutLink } from '~/services/stripe/checkout'
import { formatRecurrence, getRecurrenceIndex } from '~/services/stripe/prices'
import { previewSubscriptionUpdate, updateSubscription } from '~/services/stripe/subscription'
import { CheckoutPayload } from '~/types/stripe/checkout'
import { Plan } from '~/types/stripe/plans'
import { SubscriptionItem, SubscriptionItemType, UpdateSubscriptionPayload } from '~/types/stripe/subscription'
import ArrowRight from '~/assets/icons/arrow-right.svg'
import CheckIcon from '~/assets/icons/check.svg'
import { translations } from '~/translations'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import { getFullDate } from '~/helpers/dates'

const SubscriptionsEntreprise: Component<SubscriptionsEntrepriseProps> = ({ currentSubscriptionItems, plans }) => {
  const navigate = useNavigate()
  const [showConfirmation, setConfirmation] = createSignal(false)
  const isUpdating = currentSubscriptionItems.length > 0
  let initialRecurrenceIndex = 0
  const initialCart: Cart = currentSubscriptionItems.map(entry => {
    const plan = plans.find(plan => plan.plan.productId === entry.plan.product) as Plan
    initialRecurrenceIndex = getRecurrenceIndex({
      recurrence: entry.price.recurring as Stripe.Price.Recurring,
      plan
    })
    return {
      id: plan.plan.id,
      count: entry.quantity ?? 0
    }
  })
  const [cart, setCart] = createSignal<Cart>([], { equals: false })
  setCart(initialCart)

  const titleLabel = isUpdating ? translations().subscriptions.pro.upgrade.title : translations().subscriptions.pro.subscribe.title

  // Provide signal updaters for each plan component (EntrepriseSubscriptionEntry)
  const getLicenseCount = (planId: string) => {
    return cart().find(entry => entry.id === planId)?.count ?? 0
  }
  const setLicenseCount = (planId: string) => (direction: number) => {
    const updatedCart = cart()
    const planToUpdate = updatedCart.find(entry => entry.id === planId)
    if(planToUpdate){
      const newCount = planToUpdate.count + direction
      planToUpdate.count = newCount < 0 ? 0 : newCount
    }else{
      updatedCart.push({
        id: planId,
        count: 1
      })
    }
    setCart(updatedCart)
  }

  const totalCount = () => cart().reduce((result, entry) => {
    return result + entry.count
  }, 0)

  const subscribeLabel = () => {
    const label = isUpdating 
      ? translations().subscriptions.pro.upgrade.submit(totalCount().toString()) 
      : translations().subscriptions.pro.subscribe.submit(totalCount().toString())
    return label
  }

  const confirmationLabel = () => {
    const periodEnd = new Date()
    const label = invoices.latest?.isUpgrading
      ? translations().subscriptions.indie.upgrade.confirmImmediateBilling
      : translations().subscriptions.indie.upgrade.confirmDowngrade(getFullDate(periodEnd))
    return label
  }

  // Handle recurrence toggling
  const [reccurenceOptions, setReccurenceOptions] = createSignal<PillOption[]>([])
  const currentRecurrenceIndex = () => {
    let index = reccurenceOptions().findIndex(option => option.checked)
    if (index === -1) index = 0
    return index
  }
  createEffect(() => {
    const recurrenceOptions: PillOption[] = plans[0].prices.map((price, index) => ({
      label: formatRecurrence(price),
      value: price.id,
      checked: index === 0
    })) ?? []
    setReccurenceOptions(recurrenceOptions)
  })

  // Create a new Subscription
  const checkout = async () => {
    if (totalCount() > 0){
      setLoadingState({
        loading: true
      })

      const payload: CheckoutPayload = []

      cart().forEach(cartEntry => {
        const plan = plans.find(entry => entry.plan.id === cartEntry.id)
        const priceId = plan?.prices[currentRecurrenceIndex()].id
        if (priceId && cartEntry.count > 0) {
          payload.push({
            price: priceId,
            quantity: cartEntry.count
          })
        }
      })

      const link = await getCheckoutLink(payload)
      if (link) {
        window.location.href = link
      }

      setLoadingState({
        loading: false
      })
    }
  }

  // Update current Subscription
  const update = async () => {
    if (totalCount() > 0){
      setLoadingState({
        loading: true
      })

      const subscriptionItems: SubscriptionItem[] = []

      cart().forEach(cartEntry => {
        const plan = plans.find(entry => entry.plan.id === cartEntry.id)
        const priceId = plan?.prices[currentRecurrenceIndex()].id
        if (priceId && cartEntry.count > 0) {
          subscriptionItems.push({
            price: priceId,
            quantity: cartEntry.count
          })
        }
      })

      await updateSubscription({
        subscriptionItems,
        type: 'pro'
      })

      setLoadingState({
        loading: false
      })
    }
  }

  const submitHandler = async () => {
    if(isUpdating){
      await update()
    }else{
      await checkout()
    }
    navigate('/account/subscriptions')
  }

  const goToIndie = () => {
    navigate('/subscribe')
  }

  const updateSubscriptionPayload = () => {
    const subscriptionItems: UpdateSubscriptionPayload['subscriptionItems'] = cart().map(subscriptionItem => {
      const plan = plans.find(plan => plan.plan.id === subscriptionItem.id)
      const price = plan?.prices[currentRecurrenceIndex()].id as Stripe.Price['id']
      const type = plan?.plan.id as SubscriptionItemType
      const quantity = subscriptionItem.count
      return {
        price,
        type,
        quantity
      }
    })
    const payload: UpdateSubscriptionPayload = {
      subscriptionItems,
      type: 'pro'
    }
    return payload
  }

  const [invoices] = createResource(updateSubscriptionPayload, previewSubscriptionUpdate)

  const onSubmit = () => {
    setConfirmation(true)
  }

  return (
    <div class={styles.subscriptionsEntreprise}>
      <ConfirmationModal
        open={showConfirmation()}
        setOpen={setConfirmation}
        confirmIcon={CheckIcon}
        confirmLabel={subscribeLabel()}
        title={translations().subscriptions.pro.upgrade.title}
        description={confirmationLabel()}
        onConfirm={submitHandler}
      />

      <div class={styles.title}>
        {titleLabel}
      </div>

      <div>
        <TogglePill
          options={reccurenceOptions()}
          setOptions={setReccurenceOptions}
        />
      </div>

      <div class={styles.plans}>
        <For each={plans}>
          {entry => (
            <EntrepriseSubscriptionEntry
              subscription={entry}
              count={getLicenseCount(entry.plan.id)}
              setCount={setLicenseCount(entry.plan.id)}
              recurrenceIndex={currentRecurrenceIndex()}
            />
          )}
        </For>
      </div>

      {isUpdating && invoices.latest && (
        <SubscriptionsEntrepriseUpdateTable
          plans={plans}
          invoices={invoices.latest}
          loading={invoices.loading}
          currentSubscriptions={initialCart}
          currentSubscriptionsRecurrenceIndex={initialRecurrenceIndex}
          updatedSubscriptions={cart()}
          updatedSubscriptionsRecurrenceIndex={currentRecurrenceIndex()}
        /> 
      )}

      <div class={styles.buttonContainer}>
        {!isUpdating && (
          <div class={styles.explanation}>
            {translations().subscriptions.taxesAtNextStep}
          </div>
        )}

        <Button
          style='purple'
          onClick={onSubmit}
        >
          {subscribeLabel()}
        </Button>
      </div>

      <Button
        icon={ArrowRight}
        onClick={goToIndie}
        style='borderless'
      >
        {translations().subscriptions.indie.title}
      </Button>
    </div>
  )
}

export default SubscriptionsEntreprise
