import { createResource } from 'solid-js'
import Header from '~/components/header/header'
import SubscriptionsEntreprise from '~/components/subscriptions-entreprise/subscriptions-entreprise'
import { getPlans } from '~/services/stripe/plans'
import { getProLicenses } from '~/services/stripe/subscription'

export default function SubscribeEntreprise() {
  const [plans] = createResource(getPlans)
  const [subscriptions] = createResource(getProLicenses)

  return (
    <>
      <Header />
      {subscriptions.latest && plans.latest && (
        <SubscriptionsEntreprise
          currentSubscriptionItems={subscriptions.latest}
          plans={plans.latest.entreprisePlans}
        />
      )}
    </>
  )
}
