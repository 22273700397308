import { Component, Show } from 'solid-js'
import { SubscriptionsEntrepriseUpdateTableProps } from '~/components/subscriptions-entreprise-update-table/subscriptions-entreprise-update-table.interfaces'
import styles from '~/components/subscriptions-entreprise-update-table/subscriptions-entreprise-update-table.module.scss'
import RightArrowIcon from '~/assets/icons/arrow-right.svg'
import { formatPrice, formatRecurrence } from '~/services/stripe/prices'
import { translations } from '~/translations'
import LoadingIcon from '~/components/loading-icon/loading-icon'

const SubscriptionsEntrepriseUpdateTable: Component<SubscriptionsEntrepriseUpdateTableProps> = ({ currentSubscriptions, updatedSubscriptions, currentSubscriptionsRecurrenceIndex, updatedSubscriptionsRecurrenceIndex, plans, invoices, loading }) => {
  const currentRecurrence = () => {
    const plan = plans.find(plan => plan.plan.id === currentSubscriptions[0].id)
    const price = plan?.prices[currentSubscriptionsRecurrenceIndex]
    return price ? formatRecurrence(price, true) : ''
  }
  const updatedRecurrence = () => {
    const plan = plans.find(plan => plan.plan.id === updatedSubscriptions[0].id)
    const price = plan?.prices[updatedSubscriptionsRecurrenceIndex]
    return price ? formatRecurrence(price, true) : ''
  }

  

  const getPlanNameFromId = (id: string) => {
    return plans.find(entry => entry.plan.id === id)?.plan.title
  }

  return (
    <div class={styles.subscriptionsEntrepriseUpdateTable}>
      <table class={styles.table}>
        <tbody>
          <tr class={styles.row}>
            <td class={styles.title} colSpan={2}>
              {translations().subscriptions.pro.upgrade.currentSubscription}
            </td>
          </tr>
          {currentSubscriptions.map(line => (
            <tr class={styles.row}>
              <td class={styles.description}>
                {getPlanNameFromId(line.id)}
              </td>
              <td class={styles.amount}>
                {translations().subscriptions.pro.licenses.count(line.count)}
              </td>
            </tr>
          ))}
          <tr class={styles.taxRow}>
            <td class={styles.description}>
              {translations().subscriptions.tax}
            </td>
            <td class={styles.amount}>
              <Show
                when={!loading}
                fallback={<LoadingIcon size={12} />}
              >
                {formatPrice(invoices.current.tax)} {currentRecurrence()}
              </Show>
            </td>
          </tr>
          <tr class={styles.totalRow}>
            <td class={styles.description}>
              {translations().subscriptions.total}
            </td>
            <td class={styles.amount}>
              <Show
                when={!loading}
                fallback={<LoadingIcon size={12} />}
              >
                {formatPrice(invoices.current.total)} {currentRecurrence()}
              </Show>
            </td>
          </tr>
        </tbody>
      </table>

      <RightArrowIcon class={styles.rightIcon} />

      <table class={styles.table}>
        <tbody>
          <tr class={styles.row}>
            <td class={styles.title} colSpan={2}>
              {translations().subscriptions.pro.upgrade.upgradedSubscription}
            </td>
          </tr>
          {updatedSubscriptions.map(line => (
            <tr class={styles.row}>
              <td class={styles.description}>
                {getPlanNameFromId(line.id)}
              </td>
              <td class={styles.amount}>
                {translations().subscriptions.pro.licenses.count(line.count)}
              </td>
            </tr>
          ))}
          <tr class={styles.taxRow}>
            <td class={styles.description}>
              {translations().subscriptions.tax}
            </td>
            <td class={styles.amount}>
              <Show
                when={!loading}
                fallback={<LoadingIcon size={12} />}
              >
                {formatPrice(invoices.new.tax)} {updatedRecurrence()}
              </Show>
            </td>
          </tr>
          <tr class={styles.totalRow}>
            <td class={styles.description}>
              {translations().subscriptions.total}
            </td>
            <td class={styles.amount}>
              <Show
                when={!loading}
                fallback={<LoadingIcon size={12} />}
              >
                {formatPrice(invoices.new.total)} {updatedRecurrence()}
              </Show>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SubscriptionsEntrepriseUpdateTable
